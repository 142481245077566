<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="'Ventas de Producto'">
          <div class="row">
            <div class="col-md-6">
              <div id="graph">
                 <canvas id="product-chart" width="100%" height="100%"></canvas>
              </div>
             
            </div>
            <div class="col-md-6">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Producto</th>
                    <th scope="col">Cantidad Vendida</th>
                    <th scope="col">Total Ingresos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataproduct">
                    <td>{{ item.product.name }}</td>
                    <td>{{ item.cant_product_transaction }}</td>
                    <td>{{ item.amount_product_transaction }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>
<script></script>
<script>
import Vue from "vue";
import Chart from "chart.js";
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";

export const ProductChartData = {
  type: "doughnut",
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 3
      }
    ]
  },
  options: {
    responsive: true,
    lineTension: 1,
    legend: {
      display: false
    },
    legend: {
      display: false
    }
  }
};

export default {
  name: "new-station",
  components: {
    SpringSpinner
  },
  data() {
    return {
      productoptions: null,
      id_product_station: "",
      price: "",
      vat: "",
      dataproduct: null,
      idproduct: "",
      ProductChartData: ProductChartData,
      active: 0,
      idStation:0
    };
  },
  mounted() {
    this.ProductChartData = [];
    this.idStation = this.$route.params.idstation;
    this.loadProductsAnalyticByStation(this.idStation)
      .then(data => {
        this.dataproduct = data;
       
        console.log(data)
        
        while(ProductChartData.data.datasets[0].data.length > 0) {
            ProductChartData.data.datasets[0].data.pop();
        }
        data.forEach(function(element) {
          ProductChartData.data.labels.push(element.product.name);
          ProductChartData.data.datasets[0].data.push(
            element.cant_product_transaction
          );
          ProductChartData.data.datasets[0].backgroundColor.push(
            getRandomColor()
          );
        });
        console.log(ProductChartData)
        this.createChart("product-chart", ProductChartData);
      })
      .catch(data => {
        this.error = data.message;
      });
  },
  computed: {},

  methods: {
    ...mapActions([
      ,
      "updateProductStation",
      "addToastMessage",
      "loadProductsAnalyticByStation"
    ]),
    createChart(chartId, chartData) {
      try {
       

      


         const ctx = document.getElementById(chartId);
       
        const myChart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options
        });
        
          myChart.destroy();

           myChart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options
        });
      
      } catch (e) {
        console.log(e);
      }
    },
    getRandomColor: function() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    backPage: function() {
      this.$router.go(-1);
    }
  }
};

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
